<script>
import TablePage from '../../../../../../../components/table_page';
import configs from './config';

export default {
  extends: TablePage,
  props: {
    data: Array,
    value: [],
    selectKeys: Array,
    cusCode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      configs,
      selection: [],
      params: {
        custCode: this.cusCode,
      },
    };
  },

  created() {
    this.getConfigList('product_table', true, true);
  },
  methods: {
    afterCheckboxChange() {
      this.$emit('onSelect', this.selectRow);
    },
    afterCheckAllChange(val) {
      this.$emit('onSelect', val.selection);
    },
    afterGetList() {
      console.log('this.selectKeys', this.selectKeys);
      if (this.selectKeys.length > 0) {
        const selectList = [];
        const selectListKey = [];
        const { idKey } = this.configs.tableConfig;
        this.selectKeys.forEach((v) => {
          const row = this.tableData.filter((a) => a[idKey] === v[idKey])[0];
          if (row) {
            selectList.push(row);
            selectListKey.push(row[idKey]);
          }
        });
        this.$refs.xTable.setCheckboxRow(selectList, true);
        this.selection = selectListKey;
      }
    },
  },

};
</script>

<style>

</style>
