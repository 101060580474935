<script>
import Form, { formCreate } from '../../../../../components/form';
import request from '../../../../../utils/request';
// import form from '../../customer_information/components/addressManage/form';
// import ContantUser, { Supplys } from './components';
import Supplys from './components/supplys';
import terminalContactInfo from './components/contact_info/contact_info.vue';
// import terminalSupplys from './components/extended_info/extend_info.vue';
import terminalAdrInput from './components/adr_input.vue';
// import customerInfoProduct from './components/extended_info/product';
import Products from './components/products/products.vue';
import Goods from './components/goods/goods.vue';

formCreate.component('Supplys', Supplys);
// formCreate.component('terminalSupplys', terminalSupplys);
formCreate.component('Products', Products);
formCreate.component('Goods', Goods);
// formCreate.component('ContantUser', ContantUser);
formCreate.component('terminalContactInfo', terminalContactInfo);
formCreate.component('terminalAdrInput', terminalAdrInput);
// formCreate.component('customerInfoProduct', customerInfoProduct);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  props: {
    id: String,
    noButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      terminalType: [],
      rule: [],
      queryData: [],
      mdmCustomerContactVos: [
        {
          contactMain: '',
          contactName: '',
          contactPhone: '',
          id: new Date().getTime(),
        },
      ],
      supplys: [
        {
          supplyType: '', // 供货关系类型
          positionCode: '', // 负责人
          orgCode: '', // 组织
          customerCode: '', // 上级客户
          saleCompany: '', // 销售公司
          channel: '', // 渠道
          details: [],
          userName: '1',
          id: new Date().getTime(),
        },
      ],
      mdmCustomerContactVosItem: {
        contactMain: '',
        contactName: '',
        contactPhone: '',
      },
      supplysItem: {
        supplyType: '', // 供货关系类型
        positionCode: '', // 负责人
        orgCode: '', // 组织
        customerCode: '', // 上级客户
        saleCompany: '', // 销售公司
        channel: '', // 渠道
        userName: '',
        details: [],
      },
      showData: [],
      products: [],
      goods: [],
    };
  },
  async created() {
    if (this.noButton) {
      this.buttons = {
        submit: false,
        close: false,
        loading: false,
        submitAudit: false,
      };
    }
    await this.getFormRule('terminal_basic', 'CRM20201127000000110');
    this.showData = await this.getDic();
    this.showData.forEach((item) => {
      if (item.dictCode === 'contact_information') {
        if (item.dictValue === 'N') {
          this.hiddenFields(true, ['title2', 'contacts']);
        }
      }
      if (item.dictCode === 'supply_relationship') {
        if (item.dictValue === 'N') {
          this.hiddenFields(true, ['title3', 'supplys']);
        }
      }
      if (item.dictCode === 'supply_relationship_product') {
        // console.log(item);
        if (item.dictValue === 'N') {
          const supplyRelationship = this.getRule('supplys');
          supplyRelationship.props.showProduct = false;
        }
      }
    });
    this.hiddenFields(true, ['directSystemId', 'sellPartyId', 'storeId']);
    // 终端类型操作
    const terminalType = this.getRule('terminalType');
    terminalType.on.change = (e) => {
      if (e === '2') {
        this.hiddenFields(false, ['directSystemId', 'sellPartyId', 'storeId']);
      } else {
        this.hiddenFields(true, ['directSystemId', 'sellPartyId', 'storeId']);
      }
    };
    // 直营体系
    const directSystemId = this.getRule('directSystemId');
    directSystemId.restfulParams = { enableStatus: '009' };
    directSystemId.restful = '/mdm/mdmDirectSystemController/directSystemSelect';
    directSystemId.optionsKey = { label: 'directSystemName', value: 'directSystemId' };
    directSystemId.on.change = (e) => {
      this.setValue({
        storeId: '',
        sellPartyId: '',
      });
    };
    directSystemId.props = {
      ...directSystemId.props,
      filterable: true,
      remote: true,
      remoteParams: 'directSystemName',
    };
    // 售达方
    const sellPartyId = this.getRule('sellPartyId');
    sellPartyId.restful = '/mdm/mdmSellPartyController/list';
    sellPartyId.optionsKey = { label: 'sellPartyName', value: 'sellPartyCode' };
    sellPartyId.restfulParamsGetValue = { directSystemId: 'directSystemId' };
    sellPartyId.refresh = true;
    sellPartyId.on.change = (e) => {
      this.setValue({
        storeId: '',
      });
    };
    sellPartyId.props = {
      ...sellPartyId.props,
      filterable: true,
      remote: true,
      remoteParams: 'sellPartyName',
    };

    // 关联门店
    const storeId = this.getRule('storeId');
    storeId.restful = '/mdm/mdmCustomerAccountController/findGrabStoreList';
    storeId.optionsKey = { label: 'name', value: 'id' };
    storeId.refresh = true;
    storeId.restfulParamsGetValue = {
      directSystemId: 'directSystemId',
      sellPartyId: 'sellPartyId',
    };
    storeId.props = {
      ...storeId.props,
      filterable: true,
      remote: true,
      remoteParams: 'storeName',
    };
    // if (this.formConfig.code === 'add') {
    //   const ext18 = this.getRule('ext18'); // 负责人
    //   ext18.value = JSON.parse(localStorage.getItem('userInfo')).fullName;
    //   const ext17 = this.getRule('ext17'); // 负责人
    //   ext17.value = JSON.parse(localStorage.getItem('userInfo')).userName;
    //   const orgCode = this.getRule('orgCode'); // 负责人所属组织
    //   orgCode.value = JSON.parse(localStorage.getItem('userInfo')).orgCode;
    //   const orgName = this.getRule('orgName'); // 负责人所属组织
    //   orgName.value = JSON.parse(localStorage.getItem('userInfo')).orgName;

    //   const ext19 = this.getRule('ext19'); // 负责人所属职位
    //   ext19.value = JSON.parse(localStorage.getItem('userInfo')).positionCode;
    //   const ext20 = this.getRule('ext20'); // 负责人所属职位
    //   ext20.value = JSON.parse(localStorage.getItem('userInfo')).positionName;
    // }

    // const orgCode = this.getRule('orgCode');
    // orgCode.restful = '/mdm/baseTreeController/orgTree';
    // orgCode.headers = { functionCode: 'select-org' };
    // orgCode.restfulParams = {
    //   enableStatus: '009',
    // };
    // orgCode.props = {
    //   ...orgCode.props,
    //   props: {
    //     parent: 'parentCode', // 父级唯一标识
    //     value: 'code', // 唯一标识
    //     label: 'name', // 标签显示
    //     children: 'children', // 子级
    //   },
    // };
    const terminalAddress = this.getRule('terminalAddress');
    terminalAddress.on = {
      ...terminalAddress.on,
      change: (e) => {
        terminalAddress.props = {
          ...terminalAddress.props,
          lng: (e.lng || '').toString(),
          lat: (e.lat || '').toString(),
        };
        this.setValue({
          longitude: e.lng,
          latitude: e.lat,
        });
      },
    };
    const provinceCode = this.getRule('provinceCode');
    const cityCode = this.getRule('cityCode');
    const districtCode = this.getRule('districtCode');
    provinceCode.restful = '/mdm/mdmRegionController/selectStartWithProvince';
    provinceCode.optionsKey = { label: 'regionName', value: 'regionCode' };
    // provinceCode.restfulParams = { parentCode: '0' };
    provinceCode.props.loading = true;
    provinceCode.props = {
      ...provinceCode.props,
      loading: true,
      filterable: true,
      remote: true,
      remoteParams: 'regionName',
    };
    provinceCode.on.change = (val) => {
      this.setValue({
        cityCode: '',
        districtCode: '',
      });
      if (!val) {
        this.disabled(true, ['cityCode', 'districtCode']);
      } else {
        this.disabled(true, ['districtCode']);
        this.disabled(false, ['cityCode']);
      }
    };
    cityCode.restful = '/mdm/mdmRegionController/list';
    cityCode.optionsKey = { label: 'regionName', value: 'regionCode' };
    cityCode.restfulParamsGetValue = { parentCode: 'provinceCode' };
    cityCode.refresh = true;
    cityCode.props = {
      ...cityCode.props,
      loading: true,
      filterable: true,
      remote: true,
      remoteParams: 'regionName',
    };
    cityCode.on.change = (val) => {
      this.setValue({
        districtCode: '',
      });
      if (!val) {
        this.disabled(true, ['districtCode']);
      } else {
        this.disabled(false, ['districtCode']);
      }
    };

    // 联系人添加按钮
    const title2 = this.getRule('title2');
    const mdmCustomerContactVos = this.getRule('contacts');
    if (this.formConfig.code !== 'view') {
      title2.props.showAdd = true;
    }
    mdmCustomerContactVos.props = {
      ...mdmCustomerContactVos.props,
      contactInfoData: this.mdmCustomerContactVos,
    };
    title2.on = {
      add: () => {
        this.mdmCustomerContactVos.push({
          ...this.mdmCustomerContactVosItem,
          id: new Date().getTime(),
        });
        mdmCustomerContactVos.props = {
          ...mdmCustomerContactVos.props,
          contactInfoData: this.mdmCustomerContactVos,
        };
      },
    };
    mdmCustomerContactVos.on = {
      del: (index) => {
        this.mdmCustomerContactVos.splice(index, 1);
      },
    };

    // 联系人添加按钮
    const title3 = this.getRule('title3');
    const supplys = this.getRule('supplys');
    if (this.formConfig.code !== 'view') {
      title3.props.showAdd = true;
    }
    supplys.props = {
      ...supplys.props,
      extendInfoData: this.supplys,
    };
    title3.on = {
      add: () => {
        this.supplys.push({
          ...this.supplysItem,
          id: new Date().getTime(),
        });
        supplys.props = {
          ...supplys.props,
          extendInfoData: this.supplys,
        };
      },
    };
    supplys.on = {
      del: (index) => {
        this.supplys.splice(index, 1);
      },
      superCusInfo: (e) => {
        const ext17 = this.getRule('ext17');
        ext17.options = [];
        this.setValue({
          orgName: '',
          orgCode: '',
          ext17: '',
          ext18: '',
        });
        request.post('/mdm/mdmCustomerMsgExtController/findDockingListByCustomerCodeByPage', { customerCode: e, pageSize: 10000 }).then((res) => {
          if (res.success) {
            const tempData = [];
            for (const item of res.result.data) {
              tempData.push({
                ...item,
                label: item.fullName,
                value: item.userName,
              });
            }
            ext17.options = tempData;
            ext17.on.change = (user) => {
              for (const item of tempData) {
                if (item.userName === user) {
                  this.setValue({
                    orgName: item.orgName,
                    orgCode: item.orgCode,
                  });
                }
              }
            };
          }
        });
      },
    };
    // 联系人添加按钮
    const title5 = this.getRule('title5');
    const products = this.getRule('products');
    if (this.formConfig.code !== 'view') {
      title5.props.showAdd = true;
    }
    products.props = {
      ...products.props,
      extendInfoData: this.products,
    };
    title5.on = {
      add: () => {
        this.products.push({
          ...this.productsItem,
          id: new Date().getTime(),
        });
        products.props = {
          ...products.props,
          extendInfoData: this.products,
        };
      },
    };
    products.on = {
      del: (index) => {
        this.products.splice(index, 1);
      },
    };
    // 联系人添加按钮
    const title6 = this.getRule('title6');
    const goods = this.getRule('goods');
    if (this.formConfig.code !== 'view') {
      title6.props.showAdd = true;
    }
    goods.props = {
      ...goods.props,
      extendInfoData: this.goods,
    };
    title6.on = {
      add: () => {
        this.goods.push({
          ...this.goodsItem,
          id: new Date().getTime(),
        });
        goods.props = {
          ...goods.props,
          extendInfoData: this.goods,
        };
      },
    };
    goods.on = {
      del: (index) => {
        this.goods.splice(index, 1);
      },
    };
    // 终端类型
    const ext41 = this.getRule('ext41');
    ext41.on = {
      ...ext41.on,
      change: (e) => {
        const ext42 = this.getRule('ext42');
        ext42.options = [];
        this.setValue({
          ext42: null,
        });
        for (const item of this.terminalType) {
          if (item.dictCode === e) { // 找到了大类
            const dataList = item.children || [];
            const tempArr = [];
            dataList.forEach((t) => {
              tempArr.push({
                label: t.dictValue,
                value: t.dictCode,
              });
            });
            ext42.options = tempArr;
          }
        }
      },
    };

    districtCode.restful = '/mdm/mdmRegionController/list';
    districtCode.optionsKey = { label: 'regionName', value: 'regionCode' };
    districtCode.restfulParamsGetValue = { parentCode: 'cityCode' };
    districtCode.refresh = true;
    districtCode.props = {
      ...districtCode.props,
      loading: true,
      filterable: true,
      remote: true,
      remoteParams: 'regionName',
    };
    const shopImagePath = this.getRule('shopImagePath');
    shopImagePath.props.limit = 1;
    shopImagePath.props.accept = 'image/*';
    const licenseImagePath = this.getRule('licenseImagePath');
    licenseImagePath.props.limit = 1;
    licenseImagePath.props.accept = 'image/*';
    const customerOrgCode = this.getRule('customerOrgCode');
    customerOrgCode.restful = '/mdm/baseTreeController/customerOrgTree';
    customerOrgCode.headers = { functionCode: 'select-customer-org-up' };
    customerOrgCode.restfulParams = {
      enableStatus: '009',
    };
    customerOrgCode.props = {
      ...customerOrgCode.props,
      props: {
        parent: 'parentCode', // 父级唯一标识
        value: 'code', // 唯一标识
        label: 'name', // 标签显示
        children: 'children', // 子级
      },
    };
    // showData.map((v) => {
    //   const item = v;
    //   if (item.dictCode === 'contact_information' && item.dictValue === 'Y') {
    //     this.rule.push({
    //       type: 'ContantUser',
    //       field: 'contacts',
    //       props: {
    //         placeholder: '请输入参数名称',
    //         loading: true,
    //         clearable: true,
    //         disabled: this.formConfig.code === 'view',
    //       },
    //     });
    //   }
    //   if (item.dictCode === 'supply_relationship' && item.dictValue === 'Y') {
    //     this.rule.push({
    //       type: 'Supplys',
    //       field: 'supplys',
    //       props: {
    //         placeholder: '请输入参数名称',
    //         disabled: this.formConfig.code === 'view',
    //         loading: true,
    //         clearable: true,
    //       },
    //     });
    //   }
    //   return item;
    // });
    this.reload(this.rule);
    this.disabled(true, ['cityCode', 'districtCode']);
    this.init();
  },

  mounted() {
    this.getTerminalType();
  },
  methods: {
    // 获取
    getTerminalType() {
      request.get('/mdm/mdmdictdata/dictTree', { dictTypeCode: 'sfa_terminal_xi' }).then((res) => {
        if (res.success) {
          this.terminalType = res.result || [];
        }
      });
    },
    changeFn(val) {
      this.hiddenFields(val === '0', ['parentCode']);
    },
    init() {
      const id = this.formConfig.id || this.id;
      if (!id) return;
      request.get('/mdm/mdmTerminalController/query', { id }).then((res) => {
        if (res.success) {
          const { result } = res;
          this.mdmCustomerContactVos = !result || !result.contacts || result.contacts.length === 0
            ? [
              {
                contactMain: '',
                contactName: '',
                contactPhone: '',
                id: new Date().getTime(),
              },
            ]
            : result.contacts;
          this.supplys = !result || !result.supplys || result.supplys.length === 0
            ? [
              {
                supplyType: '', // 供货关系类型
                positionCode: '', // 负责人
                orgCode: '', // 组织
                customerCode: '', // 上级客户
                saleCompany: '', // 销售公司
                channel: '', // 渠道
                details: [],
                userName: '',
                id: new Date().getTime(),
              },
            ]
            : result.supplys;
          if (result.thisProducts && result.thisProducts.length > 0) {
            result.thisProducts = result.thisProducts.map((v) => {
              const obj = v;
              obj.urls = (obj.imgUrls || '').split(',');
              obj.imgList = ((obj.urls || []).map((p) => ({
                url: p,
              })) || []);
              return obj;
            });
          }
          if (result.competitionProducts && result.competitionProducts.length > 0) {
            result.competitionProducts = result.competitionProducts.map((v) => {
              const obj = v;
              obj.urls = (obj.imgUrls || '').split(',');
              obj.imgList = ((obj.urls || []).map((p) => ({
                url: p,
              })) || []);
              return obj;
            });
          }
          this.products = !result || !result.thisProducts || result.thisProducts.length === 0
            ? []
            : result.thisProducts;
          this.goods = !result || !result.competitionProducts || result.competitionProducts.length === 0
            ? []
            : result.competitionProducts;
          const contacts = this.getRule('contacts');
          const supplys = this.getRule('supplys');
          const products = this.getRule('products');
          const goods = this.getRule('goods');
          contacts.props = {
            ...contacts.props,
            contactInfoData: this.mdmCustomerContactVos,
          };
          supplys.props = {
            ...supplys.props,
            extendInfoData: this.supplys,
          };
          products.props = {
            ...products.props,
            extendInfoData: this.products,
          };
          goods.props = {
            ...supplys.props,
            extendInfoData: this.goods,
          };
          if (this.supplys.length > 0) {
            const ext17 = this.getRule('ext17');
            request.get('/mdm/mdmUserController/detail', { userName: result.ext17 }).then((cusRes) => {
              if (cusRes.success) {
                ext17.options = [
                  {
                    label: (cusRes.result || {}).fullName,
                    value: (cusRes.result || {}).userName,
                  },
                ];
              }
            });
            // request.post('/mdm/mdmCustomerMsgExtController/findDockingListByCustomerCodeByPage', { customerCode: this.supplys[0].customerCode, pageSize: 10000 }).then((cusRes) => {
            //   if (cusRes.success) {
            //     const tempData = [];
            //     for (const item of cusRes.result.data) {
            //       tempData.push({
            //         ...item,
            //         label: item.fullName,
            //         value: item.userName,
            //       });
            //     }
            //     ext17.options = tempData;
            //   }
            // });
          }
          if (res.result.ext41) {
            for (const item of this.terminalType) {
              if (item.dictCode === res.result.ext41) { // 找到了大类
                const dataList = item.children || [];
                const tempArr = [];
                dataList.forEach((t) => {
                  tempArr.push({
                    label: t.dictValue,
                    value: t.dictCode,
                  });
                });
                const ext42 = this.getRule('ext42');
                ext42.options = tempArr;
              }
            }
          }

          if (result && result.terminalType === '2') {
            this.hiddenFields(false, ['directSystemId', 'sellPartyId', 'storeId']);
          }
          if (!result.provinceCode && !result.cityCode) {
            this.disabled(true, ['cityCode', 'districtCode']);
          } else if (!result.cityCode) {
            this.disabled(true, ['districtCode']);
          } else {
            this.disabled(false, ['cityCode', 'districtCode']);
          }
          this.setValue(result);
          const terminalAddress = this.getRule('terminalAddress');
          terminalAddress.props = {
            ...terminalAddress.props,
            lat: result.latitude,
            lng: result.longitude,
          };
          this.queryData = result;
        }
      });
    },
    async getDic() {
      const resData = await request.post('/mdm/mdmdictdata/list', {
        dictTypeCode: 'terminal_add_section',
      });
      return resData.result;
    },
    // 提交
    submit() {
      const formData = this.getFormData();
      console.log('formData', formData);
      this.showData.forEach((item) => {
        if (item.dictCode === 'contact_information' && item.dictValue === 'Y') {
          const contacts = this.mdmCustomerContactVos.length > 0
            ? this.fApi.method('contacts', 'getFormData')()
            : [];
          formData.contacts = contacts;
        }
      });
      formData.thisProducts = ((formData.products || []).map((v) => {
        const obj = v;
        let urls = [];
        urls = obj.imgList.map((item) => item.url);
        obj.imgUrls = urls.join(',');
        return obj;
      }) || []);
      formData.competitionProducts = ((formData.goods || []).map((v) => {
        const obj = v;
        let urls = [];
        urls = obj.imgList.map((item) => item.url);
        obj.imgUrls = urls.join(',');
        return obj;
      }) || []);
      for (const item of formData.contacts) {
        if (item === null) {
          return;
        }
      }
      if (!formData.supplys || formData.supplys.length === 0) {
        this.$message.error('请填写供货关系');
        return;
      }
      for (const sp of formData.supplys) {
        if (!sp.positionCode) {
          this.$message.error('负责人必填');
          return;
        }
      }
      if (formData) {
        let url = '/mdm/mdmTerminalExpandController/save';
        if (this.formConfig && this.formConfig.code === 'add') {
          formData.approvalBusinessCode = 'mdm_terminal_default_curd';
        }
        let params = formData;
        if (this.formConfig && this.formConfig.buttonCode === 'edit') {
          url = '/mdm/mdmTerminalExpandController/update';
          params = { ...this.queryData, ...formData };
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
</script>
