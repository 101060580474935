<template>
  <div class="container">
    <div class="inputb-container">
      <el-input placeholder="请填写地址" v-model="value" />
      <el-link class="btn" @click="openModal">选择</el-link>
    </div>

    <Modal :modalConfig="modalConfig" @onClose="btnClose">
      <div>
        <Map ref="TMap" :lat="lat" :lng="lng" :circleRadio="100" @addressChange="addressChange" btnText="搜索"></Map>
      </div>
      <div class="dialog-footer">
        <el-button type="primary" @click="btnSubmit" size="small" icon="el-icon-check">
          提交
        </el-button>
        <el-button type="danger" @click="btnClose" size="small" icon="el-icon-close">
          关闭
        </el-button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/found/components/modal/components/modal.vue';
import Map from '@/found/components/tencent_map/map.vue';

export default {
  name: 'AdrInput',
  components: {
    Modal,
    Map,
  },
  props: {
    value: String,
    lat: String,
    lng: String,
  },
  data: () => ({
    modalConfig: {
      title: '选择地址',
      visible: false,
    },
    adrInfo: {},
  }),
  // watch: {
  //   value: {
  //     immediate: true,
  //     handler(val) {
  //       this.$refs.TMap.analysisAddressToLatLng(val);
  //     },
  //   },
  //   lat: {
  //     immediate: true,
  //     handler(val) {
  //       this.$refs.TMap.moveCenter(this.lat, this.lng);
  //     },
  //   },
  //   lng: {
  //     immediate: true,
  //     handler(val) {
  //       this.$refs.TMap.moveCenter(this.lat, this.lng);
  //     },
  //   },
  // },
  methods: {
    /**
     * 关闭弹窗
     */
    btnClose() {
      this.modalConfig.visible = false;
    },
    /**
     * 打开弹窗
     */
    openModal() {
      this.modalConfig.visible = true;
    },
    /**
     * 地图点击事件
    */
    addressChange(placeInfo) {
      this.adrInfo = placeInfo;
    },
    /**
     * 确定事件
     */
    btnSubmit() {
      this.$emit('input', this.adrInfo.address);
      this.$emit('change', this.adrInfo);
      this.modalConfig.visible = false;
    },
  },
};
</script>

<style lang="less" scoped>
  .inputb-container{
    display: flex;
    .btn{
      width: 80px;
    }
  }
</style>
